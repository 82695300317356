require('./bootstrap');

require('trumbowyg/dist/trumbowyg.min');

require('trumbowyg/dist/langs/de.min');

//Import Trumbowyg plugins
require('trumbowyg/dist/plugins/colors/trumbowyg.colors.min');
require('trumbowyg/dist/plugins/base64/trumbowyg.base64.min');
require('trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min');
require('trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min');
require('trumbowyg/dist/plugins/table/trumbowyg.table.min');
require('trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min');
require('trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min');

window.popper = require('@popperjs/core');
window.tempusdominus = require('@eonasdan/tempus-dominus/dist/js/tempus-dominus.min');

const fa_five = require('@eonasdan/tempus-dominus/dist/plugins/fa-five');

tempusdominus.extend(fa_five);
